import { types } from "mobx-state-tree";

export const $spinner = types
  .model({
    active: types.optional(types.boolean, false),
  })
  .actions((self) => ({
    show() {
      self.active = true;
    },
    hide() {
      self.active = false;
    },
  }))
  .create();
